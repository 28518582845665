import React from 'react';
import './App.css';

function App() {
	return (
		<div className="app">
			<div className="background-overlay"></div>
			<div className="header-section"></div>
			<div className="body-section">
				<div className="body-wrapper">
					<div className="introduction">
						<h3 className="header">Hi, I'm Ibirori</h3>
						<p className="moto">"Your one stop center for all events in <i>Rwanda</i>"</p>
					</div>
					<div className="message-content">
						<div className="explainer_image"></div>
						<p>We are cooking something great for you. Just hold on a little bit</p>
					</div>
				</div>
			</div>
			<div className="footer-section">
				<h3 className="footer-title">Get in touch</h3>
				<div className="social-media-items">
					<a href="https://x.com/IbiroriMuRwanda" target="_blank" className="social-icon x"></a>
					<a href="https://www.instagram.com/ibirorimurwanda/" target="_blank" className="social-icon instagram"></a>
					<a href="mailto:ibirorimurwanda@gmail.com" className="social-icon email"></a>
					<a href="https://www.linkedin.com/in/ibirori-tech/" target="_blank" className="social-icon linkedin"></a>
				</div>
			</div>
		</div>
	);
}

export default App;
